import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

import Styles from './footer.styles';

const Footer = () => {
  return (
    <Styles>
      <Container className="py-5">
        <Row>
          <Col xs={12} md={4}>
            <ul className={'nav justify-content-center justify-content-md-start'}>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/process/">Process</Link>
              </li>
              <li>
                <Link to="/work/">Work</Link>
              </li>
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/office-hours/" className="text-info">
                  Office Hours
                </Link>
              </li>
            </ul>
          </Col>

          <hr className="hr-small d-block d-md-none my-4 ml-md-3" />

          <Col xs={12} md={4} className={'text-center text-md-left'}>
            <div>
              <p>
                We take on social impact projects pro-bono. How can we help you
                help others?
              </p>
              <Link to="/social-impact/" className="btn btn-primary btn-sm">
                Get Involved
              </Link>
            </div>
          </Col>

          <hr className="hr-small d-block d-md-none my-4 ml-md-3" />

          <Col xs={12} md={4} className={'text-center text-md-left'}>
            <div>
              <address className="mb-3">
                Generativ
                <br />
                c/o Neuehouse
                <br />
                110 East 25th Street
                <br />
                New York, NY 10010
              </address>
              Or say <a href="mailto:hello@generativ.io">hello@generativ.io</a>
            </div>
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};

export default Footer;
