import React from 'react';
import { Link } from 'gatsby';
import { Container, Navbar } from 'react-bootstrap';

import Styles from './topNav.styles';
import { ReactComponent as LogoBlack } from '../../images/logos/logo-black.svg';

const TopNav = () => (
  <Styles>
    <Container fluid={true} className="px-0">
      <Navbar>
        <Link to="/" className="navbar-brand" aria-label="Home">
          <LogoBlack alt="Generativ Logo" />
        </Link>

        <ul className="nav navbar-nav ml-auto d-none d-md-flex">
          <li>
            <Link to="/process/">Process</Link>
          </li>
          <li>
            <Link to="/work/">Work</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
          <li>
            <Link to="/prototyping/">Prototyping</Link>
          </li>
          <li>
            <Link to="/social-impact/">Social Impact</Link>
          </li>
          <li>
            <Link to="/office-hours/" className="text-info">
              Office Hours
            </Link>
          </li>
        </ul>
      </Navbar>
    </Container>
  </Styles>
);

export default TopNav;
