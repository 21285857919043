import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { push as Menu } from 'react-burger-menu';

import Styles from './mobileNav.styles';

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

// create a button that calls a context function to set a new open state when clicked
const Button = () => {
  const ctx = useContext(MyContext);
  return (
    <button onClick={ctx.toggleMenu} className="d-block d-md-none">
      Menu
    </button>
  );
};

// create a navigation component that wraps the burger menu
const Navigation = () => {
  const ctx = useContext(MyContext);

  return (
    <Menu
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      pageWrapId={'wrap'}
      outerContainerId={'outer'}
      width={'75vw'}
      right
    >
      <ul className='list-unstyled'>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/process/">Process</Link>
        </li>
        <li>
          <Link to="/work/">Work</Link>
        </li>
        <li>
          <Link to="/about/">About</Link>
        </li>
        <li>
          <Link to="/prototyping/">Prototyping</Link>
        </li>
        <li>
          <Link to="/social-impact/">Social Impact</Link>
        </li>
        <li>
          <Link to="/office-hours/" className="text-info">
            Office Hours
          </Link>
        </li>
      </ul>
    </Menu>
  );
};

const MobileNav = () => (
  <Styles>
    <MyProvider>
      <div>
        <Button />
        <Navigation />
      </div>
    </MyProvider>
  </Styles>
);

export default MobileNav;
