import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';
import utilities from '../../styles/modules/utilities.module.scss';

const Styles = styled.div({
  svg: {
    height: utilities.spacer,
    marginTop: `-${rhythm(1 / 8)}`,
  },

  li: {
    a: {
      lineHeight: '1rem',
    },
  },
});

export default Styles;
