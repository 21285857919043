import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyles from '../../utils/global.styles';
import TopNav from '../topNav/topNav';
import Footer from '../footer/footer';
import MobileNav from '../mobileNav/mobileNav';

const Layout = ({ children }) => {
  return (
    <div id="outer">
      <GlobalStyles />
      <MobileNav />
      <div id="wrap">
        <TopNav />
        <>{children}</>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
