import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';
import colors from '../../styles/modules/colors.module.scss';

const Styles = styled.div({
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    padding: `${rhythm(3 / 4)} ${rhythm(1)}`,
    background: 'none',
    border: 'none',
  },

  '.bm-menu-wrap': {
    height: '100vh',
    backgroundColor: colors.white,
    paddingTop: `${rhythm(1)}`,

    li: {
      a: {
        padding: `${rhythm(1 / 2)} ${rhythm(3 / 2)}`,
        color: colors.primary,
        fontSize: `${rhythm(1)}`,
      },
    },
  },

  'button, .bm-menu-wrap, .bm-menu, .bm-item-list, .bm-item': {
    ':focus': {
      outline: 'none',
    },
  },
});

export default Styles;
